import React from 'react'

//Data
import { observer } from 'mobx-react-lite'
import { useStores } from '../../hooks/useStores'

//Components
import ConfermaChiusuraAsideModal from './ConfermaChiusuraAsideModal'
import { useTranslation } from 'react-i18next'

export const AsideModal = observer(() => {

  const styles = {
    aside: "z-[501] h-screen overflow-hidden bg-white dark:bg-darkgray-dd shadow-2xl fixed  right-0 border-l border-lightgray-dd aside-animato",
    asideContainer: "w-full relative flex flex-col overflow-y-auto h-screen pt-[30px]",
    chiudi: "p-2 cursor-pointer text-white bg-red dark:bg-dark-red text-center rounded-sm w-full capitalize"
  }

  const { t } = useTranslation()
  const { ui } = useStores();

  return (
    <>
      {ui.bloccaUi && <div className="fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.3)] z-[500]" />}
      {ui.bloccaUi}
      <aside className={styles.aside}>

        <div className={styles.asideContainer}>
          <div className="w-full relative p-2 lg:p-4">
            {ui.asideModalComponent}

            {ui.ConfermaChiusuraAsideModal && <ConfermaChiusuraAsideModal />}

          </div>

          {!ui.ConfermaChiusuraAsideModal &&
            <div className="w-full px-2 pb-2">
              <button className={styles.chiudi} onClick={() => ui.unmountConfermaChiusuraAsideModal()}>
                {t('common.chiudi')}
              </button>
            </div>
          }


        </div>
      </aside>
    </>
  )
})